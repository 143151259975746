import GlobalRound from "@/res/assets/icons/flags-rounded/globe.svg";

import UKRound from "@/res/assets/icons/flags-rounded/united-kingdom.svg";
import UKSquare from "@/res/assets/icons/flags/great_britain.svg";

import USRound from "@/res/assets/icons/flags-rounded/united-states.svg";
import USSquare from "@/res/assets/icons/flags/united-states.svg";

import NORound from "@/res/assets/icons/flags-rounded/norway.svg";
import NOSquare from "@/res/assets/icons/flags/norway.svg";

import DERound from "@/res/assets/icons/flags-rounded/germany.svg";
import DESquare from "@/res/assets/icons/flags/germany.svg";

import INRound from "@/res/assets/icons/flags-rounded/india.svg";
import INSquare from "@/res/assets/icons/flags/india.svg";

export const Countries = {
    // "global": {
    //     country: "global",
    //     country_details: {
    //         name: "Global",
    //         name_english: "Global",
    //         flag: { square: GlobalRound, round: GlobalRound },
    //     },
    // },
    "no": {
        ranking: 999,
        country: "no",
        country_details: {
            name: "Norge",
            name_english: "Norway",
            flag: { square: NOSquare, round: NORound },
        },
        country_settings: {
            aml_enabled: true,
        },
    },
    // "uk": {
    //     country: "uk",
    //     country_details: {
    //         name: "United Kingdom",
    //         name_english: "United Kingdom",
    //         flag: { square: UKSquare, round: UKRound },
    //     },
    //     country_settings: {},
    // },
    // "us": {
    //     country: "us",
    //     country_details: {
    //         name: "United States",
    //         name_english: "United States",
    //         flag: { square: USSquare, round: USRound },
    //     },
    //     country_settings: {},
    // },
    // "de": {
    //     country: "de",
    //     country_details: {
    //         name: "Deutschland",
    //         name_english: "Germany",
    //         flag: { square: DESquare, round: DERound },
    //     },
    //     country_settings: {},
    // },
    // "in": {
    //     country: "in",
    //     country_details: {
    //         name: "India",
    //         name_english: "India",
    //         flag: { square: INSquare, round: INRound },
    //     },
    //     country_settings: {},
    // },
}
