import ServicesImg from "@/res/assets/services/services.svg";
import AccountingImg from "@/res/assets/services/accounting.svg";
import AuditingImg from "@/res/assets/services/auditing.svg";
import AdvertisementImg from "@/res/assets/services/advertisement.svg";
import LegalImg from "@/res/assets/services/legal.svg";
import RecruitingImg from "@/res/assets/services/recruiting.svg";
import ManagementConsultingImg from "@/res/assets/services/management-consulting.svg";
import CrmImg from "@/res/assets/services/crm.svg";
import CrmEnterpriseImg from "@/res/assets/services/crm-enterprise.svg";
import LegalImage from "@/res/assets/images/legal.png";
import AccountingImage from "@/res/assets/images/accounting.png";
import AuditingImage from "@/res/assets/images/auditing.png";

import { Countries } from "@/res/data/Countries";

export const Paths = [
    { params: {
        ...Countries["no"],
        service: "regnskapsfoerer",
        service_details: {
            code: "ac", label: "Regnskapsfører", label_english: "Accounting", icon: AccountingImg, image: AccountingImage,
            settings: {
                lookup: true, multi_mode: false,
            },
        },
    }},
    { params: {
        ...Countries["no"],
        service: "advokat",
        service_details: {
            code: "le", label: "Advokat", label_english: "Legal", icon: LegalImg, image: LegalImage,
            settings: {
                lookup: true, multi_mode: true, in_development: false, default_mode: "PERSONAL",
            },
        },
    }},
    { params: {
        ...Countries["no"],
        service: "revisor",
        service_details: {
            code: "au", label: "Revisjon", label_english: "Auditing", icon: AuditingImg, image: AuditingImage,
            settings: {
                lookup: true, multi_mode: false,
            },
        },
    }},

    // { params: {
    //     ...Countries["uk"],
    //     service: "placeholder",
    // }},

    // { params: {
    //     ...Countries["uk"],
    //     service: "legal",
    //     service_details: {
    //         code: "le", label: "Legal", label_english: "Legal", icon: LegalImg,
    //         settings: {
    //             lookup: false, multi_mode: false, in_development: true,
    //         },
    //     },
    // }},

    // { params: {
    //     ...Countries["us"],
    //     service: "accounting",
    //     service_details: {
    //         code: "ac", label: "Accounting", label_english: "Accounting", icon: AccountingImg,
    //         settings: {
    //             lookup: false, multi_mode: false, in_development: false,
    //         },
    //     },
    // }},
    //
    // { params: {
    //     ...Countries["de"],
    //     service: "legal",
    //     service_details: {
    //         code: "le", label: "Legal", label_english: "Legal", icon: LegalImg,
    //         settings: {
    //             lookup: false, multi_mode: true, in_development: false,
    //         },
    //     },
    // }},
    //
    // { params: {
    //     ...Countries["in"],
    //     service: "services",
    //     service_details: {
    //         code: "se", label: "Services", label_english: "Services", icon: ServicesImg,
    //         settings: {
    //             lookup: false, multi_mode: false,
    //         },
    //     },
    // }},
];

export const ExtraPaths = [
    // { params: {
    //     ...Countries["no"],
    //     service: "crm",
    //     service_details: {
    //         label: "CRM+", icon: CrmImg,
    //     },
    // }},
    // { params: {
    //     ...Countries["no"],
    //     service: "crm/enterprise",
    //     service_details: {
    //         label: "CRM+ Enterprise", icon: CrmEnterpriseImg,
    //     },
    // }},
    //
    // { params: {
    //     ...Countries["uk"],
    //     service: "crm",
    //     service_details: {
    //         label: "CRM+", icon: CrmImg,
    //     },
    // }},
    // { params: {
    //     ...Countries["uk"],
    //     service: "crm/enterprise",
    //     service_details: {
    //         label: "CRM+ Enterprise", icon: CrmEnterpriseImg,
    //     },
    // }},
];

export const CanLookupBusiness = (country) => {
    let canLookup = false;
    Paths.forEach((path) => {
        if (path?.params?.country?.toLowerCase() === country?.toLowerCase()) {
            canLookup = path?.params?.service_details?.settings?.lookup ?? false;
        }
    })

    return canLookup;
};

export const PathDetails = (country, service) => {
    let details = {}

    Paths.forEach((path) => {
        if (path?.params?.country?.toLowerCase() === country?.toLowerCase() && path?.params?.service?.toLowerCase() === service?.toLowerCase()) {
            details = path?.params?.service_details ?? {};
            details.settings = { ...(Object.keys(path?.params?.country_settings ?? {}).length > 0 ? path?.params?.country_settings : {}), ...(details.settings) }
        }
    })

    return details;
}
